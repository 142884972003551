import React from 'react';

type LabelProps = {
  className?: string;
  label: string;
  required?: boolean;
};

const Label: React.FC<LabelProps> = (props) => {
  return (
    <div
      className={`inline-flex shrink-0 items-center text-gray-900 ${props.className}`}
    >
      <label className="inline-flex cursor-pointer items-center gap-0.5 text-base font-medium">
        <span>{props.label}</span>
        {props.required && <span className="font-normal text-red-500">*</span>}
      </label>
    </div>
  );
};

export default Label;
